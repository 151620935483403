<template>
  <div>
    <section id="knowledge-base-content">
      <b-row
          v-if="userData.pack !== 1"
          class="match-height"
      >
        <b-col lg="6">
          <analytics-support-tracker :data="data.supportTracker" />
        </b-col>
        <b-col lg="3">
          <analytics-sales-radar-chart :data="data.salesChart" />
        </b-col>
        <b-col lg="3" />
      </b-row>
      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
            xl="2"
            lg="3"
            md="4"
            sm="6"
            class="kb-search-content"
        >
          <b-card
              class="text-center cursor-pointer"
              @click="$router.push({ name: 'apps-users-list' })"
          >
            <feather-icon
                icon="UsersIcon"
                size="35"
                class="mb-1 text-primary"
            />
            <h5 class="mb-0">
              Gestion des Utilisateurs
            </h5>
          </b-card>
        </b-col>
        <b-col
            xl="2"
            lg="3"
            md="4"
            sm="6"
            class="kb-search-content"
        >
          <b-card
              class="text-center cursor-pointer"
              @click="$router.push({ name: 'apps-administration-parametres'})"
          >
            <feather-icon
                icon="SettingsIcon"
                size="35"
                class="mb-1 text-primary"
            />
            <h5 class="mb-0">
              Paramètres
            </h5>

          </b-card>
        </b-col>
        <b-col
            xl="2"
            lg="3"
            md="4"
            sm="6"
            class="kb-search-content"
        >
          <b-card
              class="text-center cursor-pointer"
              @click="$router.push({ name: 'apps-administration-maj'})"
          >
            <feather-icon
                icon="FileTextIcon"
                size="35"
                class="mb-1 text-primary"
            />
            <h5 class="mb-0">
              Mises à jour
            </h5>

          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { ref } from '@vue/composition-api'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AdministrationSidebar from './AdministrationSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    AnalyticsSupportTracker,
    AnalyticsSalesRadarChart,
    AdministrationSidebar,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      kb: [],
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      data: null,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
    /*this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'horizontal')*/
  },
  created() {
    this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    this.$store.commit('appConfig/UPDATE_SKIN', 'semi-dark')
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const isTaskHandlerSidebarActive = ref(false)
    const isTicketViewSidebarActive = ref(false)

    return {
      mqShallShowLeftSidebar,
      isTaskHandlerSidebarActive,
      isTicketViewSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
